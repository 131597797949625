<template>
  <div class="page agreement">
    <HeadBar
      class="agreement-headbar"
      title="雷石 KTV 用户服务协议"
      :isShowLogo="false"
      :isShowSinging="false"
      :isShowSetting="false"
      :isShowRight="false"
      :isSearch="false"
      :isShowSearchImg="false"
      :isShowTimesInfo="false"
    />
    <div class="agreement-content">
      <p class="text-indent-2">
        为使用雷石KTV服务（以下简称“本服务”），您应当阅读并遵守《雷石KTV用户协议》（以下简称“本协议”）。请您务必审慎阅读、充分理解各条款内容，特别是限制或免除责任条款，以及开通或使用某项服务的单独协议。
      </p>
      <p class="text-indent-2">
        除非您已阅读并接受本协议所有条款，否则您无权使用本服务。您对本服务的登录、查看、下载、分享、使用等行为即视为您已阅读并同意受本协议的约束。
      </p>
      <p class="text-indent-2">
        如果您未满18周岁，请在法定监护人的陪同下阅读本协议，并特别注意未成年人使用条款。
      </p>
      <p>一、【协议的范围】</p>
      <p>
        1.1本协议是您与雷石KTV之间关于用户使用雷石KTV服务所订立的协议。“用户”是指使用雷石KTV服务的使用人，在本协议中更多地称为“您”。
      </p>
      <p>
        1.2本协议项下的雷石KTV服务，是由雷石KTV为广大用户提供的，用户可以通过雷石KTV服务进行音乐歌曲演唱、录制、合成、上传等（以下简称“本服务”）。
      </p>
      <p>
        1.3本协议内容同时包括雷石KTV可能不断发布的关于本服务的相关协议、业务规则等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。
      </p>
      <p>二、【用户个人信息保护】</p>
      <p>2.1保护用户个人信息是雷石KTV的一项基本原则。</p>
      <p>
        2.2您在注册帐号（如涉及）或使用本服务的过程中，可能需要填写一些必要的信息。若国家法律法规有特殊规定的，您需要填写真实的身份信息。若您填写的信息不完整，则无法使用本服务或在使用过程中受到限制。
      </p>
      <p>
        2.3一般情况下，您可随时浏览、修改自己提交的信息，但出于安全性和身份识别的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。
      </p>
      <p>
        2.4未经您的同意，雷石KTV不会向雷石KTV及雷石KTV所属公司及集团以外的任何公司、组织和个人披露您的个人敏感信息，但法律法规或本协议另有规定的除外。
      </p>
      <p>2.5您理解并同意：为改善用户体验及提供本服务，雷石KTV可以：</p>
      <p>
        （1）使用您提交的个人信息，包括但不限于您的注册信息、在本服务中的相关操作及其他个人信息，并可通过本服务或本服务中的第三方向您本人、其他用户展示。
      </p>
      <p>
        （2）因您在注册或开通某项服务、功能时选择同意，或因您要求雷石KTV或第三方提供特定服务时，雷石KTV需要将您的相关个人信息提供给与此相关联的第三方或其他用户。
      </p>
      <p>三、【使用本服务的方式】</p>
      <p>3.1除非您与雷石KTV另有约定，您同意本服务仅为您个人使用。</p>
      <p>
        3.2您应当通过雷石KTV提供或认可的方式使用本服务。您依本协议条款所取得的权利不可转让。
      </p>
      <p>
        3.3您不得使用未经雷石KTV授权的插件、外挂或第三方工具对本协议项下的服务进行干扰、破坏、修改或施加其他影响。
      </p>
      <p>四、【按现状提供服务】</p>
      <p>
        您理解并同意，本服务是按照现有技术和条件所能达到的现状提供的。雷石KTV会尽最大努力向您提供服务，确保服务的连贯性和安全性；但雷石KTV不能随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。
      </p>
      <p>五、【自备设备】</p>
      <p>
        5.1您应当理解，您使用本服务需自行准备与相关服务有关的终端设备（如电脑、手机、平板电脑、上网设备、语音输入设备、调制解调器等装置），并承担所需的费用（如电话费、上网费等费用）。
      </p>
      <p>
        5.2您理解并同意，您使用本服务时会耗用您的包括但不限于终端设备、互联网网络、移动互联网网络等资源。
      </p>
      <p>六、【收费服务】</p>
      <p>
        6.1
        雷石KTV的部分服务可能是以收费方式提供的，如您使用收费服务，请遵守相关的协议。
      </p>
      <p>
        6.2
        雷石KTV可能根据实际需要对收费服务的收费标准、方式进行修改和变更，雷石KTV也可能会对部分免费服务开始收费。如果您继续使用前述修改、变更或付费内容，视为您接受变更后的收费标准及收费方式。如果您不同意上述修改、变更或付费内容，则应停止使用本服务。
      </p>
      <p>七、【第三方提供的产品或服务】</p>
      <p>
        您在使用本服务过程中使用第三方提供的产品或服务时，除遵守本协议约定外，还应遵守第三方的用户协议。雷石KTV和第三方对可能出现的纠纷在法律规定和约定的范围内各自承担责任。
      </p>
      <p>八、【本服务软件形式】</p>
      <p>雷石KTV向您提供服务时，您还应遵守以下约定：</p>
      <p>
        8.1
        雷石KTV可能为不同的终端设备开发不同的软件版本，您应当根据实际需要选择下载合适的版本进行安装。您知悉并同意：您的电脑下载、安装本软件时，本软件可能同时包含适用于移动终端设备的软件版本，当您的移动终端设备（包括但不限于手机）与您电脑连接时，可能会推荐您使用适用于移动终端设备的软件版本。
      </p>
      <p>
        8.2
        如果您从未经合法授权的第三方获取本软件或与本软件名称相同的安装程序，雷石KTV将无法保证该软件能否正常使用，并对因此给您造成的任何损失不予负责。
      </p>
      <p>
        8.3
        除非您与雷石KTV另有约定，您同意本服务及软件仅为您个人使用。您不得为其他未经雷石KTV允许的第三方安装、使用、运行本软件。
      </p>
      <p>
        8.4
        为了增进用户体验、完善服务内容，雷石KTV将不时提供软件自动或手动更新服务（该更新可能会采取软件替换、修改、功能强化、版本升级等形式）。如雷石KTV自动更新服务后，您继续使用新版雷石KTV提供的软件或服务，即视为您已接受更新后的版本。如果您不接受更新后的版本，应当停止使用雷石KTV提供的软件或服务。
      </p>
      <p>
        8.5
        软件新版本发布后，旧版软件可能无法使用。雷石KTV不保证旧版软件继续可用及相应的客户服务，请您随时核对并下载最新版本。
      </p>
      <p>8.6 除非法律允许或雷石KTV书面许可，您不得从事下列行为：</p>
      <p>（1）删除软件及其副本上关于著作权的信息；</p>
      <p>
        （2）对软件进行反向工程、反向汇编、反向编译或者以其他方式尝试发现软件的源代码；
      </p>
      <p>
        （3）对雷石KTV拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等、擅自借助本“软件”及相关信息发展与之有关的衍生产品、作品、服务、插件、外挂、兼容、互联等；
      </p>
      <p>
        （4）对软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经合法授权的第三方工具/服务接入软件和相关系统；
      </p>
      <p>
        （5）修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论上述行为是否为商业目的；
      </p>
      <p>
        （6）通过非雷石KTV开发、授权的第三方软件、插件、外挂、系统，登录或使用软件及服务，或制作、发布、传播上述工具；
      </p>
      <p>
        （7）进行任何危害计算机网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/帐号；未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；未经许可，企图探查、扫描、测试本软件系统或网络的弱点或其它实施破坏网络安全的行为；企图干涉、破坏本软件系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；伪造TCP/IP数据包名称或部分名称；
      </p>
      <p>
        （8）通过修改或伪造软件作品运行中的指令、数据、数据包，增加、删减、变动软件的功能或运行效果，及将用于上述用途的软件通过信息网络向公众传播或者运营；
      </p>
      <p>（9）其他未经雷石KTV明示授权的行为。</p>
      <p>8.7 若您实施前款所述的行为，雷石KTV将保留依法追究法律责任的权利。</p>
      <p>
        8.8
        本软件可能会使用第三方软件或技术（包括本软件可能使用的开源代码和公共领域代码等，下同），这种使用已经获得合法授权。
      </p>
      <p>
        8.9
        本软件如果使用了第三方的软件或技术，雷石KTV将按照相关法规或约定，对相关的协议或其他文件，可能通过本协议附件、在本软件安装包特定文件夹中打包以及其他形式进行展示，它们可能会以“软件使用许可协议”、“授权协议”、“开源代码许可证”或其他形式来表达。前述通过各种形式展现的相关协议或其他文件，均是本协议不可分割的组成部分，与本协议具有同等的法律效力，您应当遵守这些要求。如果您没有遵守这些要求，该第三方或者国家机关可能会对您提起诉讼、罚款或采取其他制裁措施，并要求雷石KTV给予协助，您应当自行承担法律责任。
      </p>
      <p>
        8.10
        如因本软件使用的第三方软件或技术引发的任何纠纷，应由该第三方负责解决，雷石KTV不承担任何责任。雷石KTV不对第三方软件或技术提供客服支持，若您需要获取支持，请与第三方联系。
      </p>
      <p>九、【用户行为规范】</p>
      <p>9.1 您在使用本服务时不得利用本服务从事以下行为，包括但不限于：</p>
      <p>
        （1）发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽、暴力的内容；
      </p>
      <p>
        （2）发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；
      </p>
      <p>
        （3）使用任何包含有通过侵犯知识产权、商业机密或任何一方的其他合法权利的方式利用本服务获得的相应的音频、视频资料；
      </p>
      <p>
        （4）制作、发布、传播用于窃取用户帐号及他人专属信息、财产、保密信息的软件；
      </p>
      <p>
        （5）在未经雷石KTV书面明确授权前提下，出售、出租、出借、散布、转移或转授权本软件和本服务或相关的链接或从使用软件和服务或软件和服务的条款中获利，无论以上使用是否为直接经济或金钱收益；
      </p>
      <p>
        （6）未经雷石KTV允许，擅自将本“软件”及雷石KTV内容资源库中的任何音乐资源应用于商业用途或进行营利活动；
      </p>
      <p>（7）虚构事实、隐瞒真相以误导、欺骗他人；</p>
      <p>（8）发表、传送、传播垃圾信息；</p>
      <p>
        （9）以其他以任何不合法的方式、为任何不合法的目的、或以任何与本协议不一致的方式使用本软件和本服务；
      </p>
      <p>（10）从事其他违反法律法规、政策及公序良俗、社会公德等的行为。</p>
      <p>
        9.2
        您通过本服务发送或传播的内容（包括但不限于网页、文字、图片、音频、视频、图表等）均由您自行承担责任。
      </p>
      <p>
        9.3
        您通过本服务发送或传播的内容应有合法来源，并保证相关内容为您所有或您已获得权利人的授权。
      </p>
      <p>
        9.4
        您同意雷石KTV可为履行本协议、提供本服务或宣传推广本服务之目的而使用您发送或传播的包括但不限于图片、文字、音乐等所有内容；并对雷石KTV作出不可撤销的永久的，因使用您发送或传播内容的所必须的知识产权、民事权利等之许可。
      </p>
      <p>
        9.5
        如果雷石KTV发现或收到他人举报您发布的信息或内容违反前述约定或本协议其他约定的，雷石KTV有权进行独立判断并采取技术手段予以删除、屏蔽或断开链接。同时，雷石KTV有权视用户的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止账号使用，追究法律责任等措施。
      </p>
      <p>
        9.6
        如果您违反了本条约定，相关国家机关或机构可能会对您提起诉讼、罚款或采取其他制裁措施，并要求雷石KTV给予协助。造成损害的，您应依法予以赔偿，雷石KTV不承担任何责任。
      </p>
      <p>
        9.7
        您违反本协议约定，导致任何第三方损害的，您应当独立承担责任；雷石KTV因此遭受损失的，您也应当一并赔偿。
      </p>
      <p>十、【知识产权声明】</p>
      <p>
        10.1
        雷石KTV在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权归雷石KTV所有，用户在使用本服务中所产生的内容的知识产权归用户或相关权利人所有。您同意，将使用本服务所产生内容的知识产权授权雷石KTV，供雷石KTV在本服务及其他雷石KTV运营的服务中以包括但不限于复制、下载和传播等方式进行使用。
      </p>
      <p>
        10.2
        除另有特别声明外，雷石KTV提供本服务时所依托软件的著作权、专利权及其他知识产权均归雷石KTV所有。
      </p>
      <p>
        10.3
        上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经雷石KTV、用户或相关权利人书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。
      </p>
      <p>
        10.4
        您同意，其他用户可以在本服务中以上传、下载、分享、收听或与您合唱等方式使用您演唱的完整的歌曲或者歌曲的片段。
      </p>
      <p>十一、【遵守当地法律监管】</p>
      <p>
        11.1
        您在使用本服务过程中应当遵守当地相关的法律法规，并尊重当地的道德和风俗习惯。如果您的行为违反了当地法律法规或道德风俗，您应当为此独立承担责任。
      </p>
      <p>
        11.2
        您应避免因使用本服务而使雷石KTV卷入政治和公共事件，否则雷石KTV有权暂停或终止对您的服务。
      </p>
      <p>十二、【不可抗力及其他免责事由】</p>
      <p>
        12.1
        您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，雷石KTV将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失雷石KTV在法律允许的范围内免责。
      </p>
      <p>
        12.2
        在法律允许的范围内，雷石KTV对以下情形导致的服务中断或受阻不承担责任：
      </p>
      <p>（1）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；</p>
      <p>（2）用户或雷石KTV的电脑软件、系统、硬件和通信线路出现故障；</p>
      <p>（3）用户操作不当；</p>
      <p>（4）用户通过非雷石KTV授权的方式使用本服务；</p>
      <p>（5）其他雷石KTV无法控制或合理预见的情形。</p>
      <p>
        12.3
        您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，雷石KTV不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给您造成的损害负责。这些风险包括但不限于：
      </p>
      <p>（1）来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息；</p>
      <p>
        （2）因使用本协议项下的服务，遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失；
      </p>
      <p>（3）其他因网络信息或用户行为引起的风险。</p>
      <p>
        12.4
        您理解并同意，本服务并非为某些特定目的而设计，包括但不限于核设施、军事用途、医疗设施、交通通讯等重要领域。如果因为软件或服务的原因导致上述操作失败而带来的人员伤亡、财产损失和环境破坏等，雷石KTV不承担法律责任。
      </p>
      <p>
        12.5
        雷石KTV依据本协议约定获得处理违法违规内容的权利，该权利不构成雷石KTV的义务或承诺，雷石KTV不能保证及时发现违法行为或进行相应处理。
      </p>
      <p>
        12.6
        在任何情况下，您不应轻信借款、索要密码或其他涉及财产的网络信息。涉及财产操作的，请一定先核实对方身份，并请经常留意雷石KTV有关防范诈骗犯罪的提示。
      </p>
      <p>十三、【协议的生效与变更】</p>
      <p>13.1 您使用雷石KTV的服务即视为您已阅读本协议并接受本协议的约束。</p>
      <p>
        13.2
        雷石KTV有权在必要时修改本协议条款。您可以在相关服务页面查阅最新版本的协议条款。
      </p>
      <p>
        13.3
        本协议条款变更后，如果您继续使用雷石KTV提供的软件或服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用雷石KTV提供的软件或服务。
      </p>
      <p>十四、【服务的变更、中断、终止】</p>
      <p>
        14.1 雷石KTV可能会对服务内容进行变更，也可能会中断、中止或终止服务。
      </p>
      <p>
        14.2
        如发生下列任何一种情形，雷石KTV有权不经通知而中断或终止向您提供的服务：
      </p>
      <p>
        （1）根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；
      </p>
      <p>（2）您违反相关法律法规或本协议的约定；</p>
      <p>（3）按照法律规定或主管部门的要求；</p>
      <p>（4）出于安全的原因或其他必要的情形。</p>
      <p>
        14.3
        雷石KTV有权按本协议8.2条的约定进行收费。若您未按时足额付费，雷石KTV有权中断、中止或终止提供服务。
      </p>
      <p>
        14.4
        您有责任自行备份存储在本服务中的数据。如果您的服务被终止，雷石KTV可以从服务器上永久地删除您的数据。服务终止后，雷石KTV没有义务向您返还数据。
      </p>
      <p>十五、【管辖与法律适用】</p>
      <p>15.1 本协议签订地为中华人民共和国北京市朝阳区。</p>
      <p>
        15.2
        本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
      </p>
      <p>
        15.3
        若您和雷石KTV之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。
      </p>
      <p>
        15.4
        本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
      </p>
      <p>
        15.5
        本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
      </p>
      <p>十六、【未成年人使用条款】</p>
      <p>
        16.1
        若用户未满18周岁，则为未成年人，应在监护人监护、指导下阅读本协议和使用本服务。
      </p>
      <p>
        16.2
        未成年人用户涉世未深，容易被网络虚象迷惑，且好奇心强，遇事缺乏随机应变的处理能力，很容易被别有用心的人利用而又缺乏自我保护能力。因此，未成年人用户在使用本服务时应注意以下事项，提高安全意识，加强自我保护：
      </p>
      <p>
        （1）认清网络世界与现实世界的区别，避免沉迷于网络，影响日常的学习生活；
      </p>
      <p>
        （2）填写个人资料时，加强个人保护意识，以免不良分子对个人生活造成骚扰；
      </p>
      <p>（3）在监护人或老师的指导下，学习正确使用网络；</p>
      <p>
        （4）避免陌生网友随意会面或参与联谊活动，以免不法分子有机可乘，危及自身安全。
      </p>
      <p>
        16.3
        监护人、学校均应对未成年人使用本服务时多做引导。特别是家长应关心子女的成长，注意与子女的沟通，指导子女上网应该注意的安全问题，防患于未然。
      </p>
      <p>十七、【其他】</p>
      <p>
        如果您对本协议或本服务有意见或建议，可与雷石KTV运营部门联系，我们会给予您必要的帮助。
      </p>
      <p>官方邮箱：thundercs@thunder.com.cn</p>
    </div>
  </div>
</template>
<script>
import { sendLog } from '@/directives/v-log/log'
import { onMounted } from 'vue'

export default {
  name: 'AgreementUser',
  setup() {
    onMounted(() => {
      sendLog({
        event_type: '10000~50000',
        event_name: 10032,
        event_data: {
          str1: '我的',
          str2: '用户协议',
          str3: '进入协议',
          str4: 'click',
        },
      })
    })

    return {}
  },
}
</script>
<style lang="stylus" scoped>
.agreement
  display flex
  flex-direction column
  height 100vh
  overflow hidden !important
  padding-top 0!important
  padding-bottom 0!important
  padding 0px
  background: linear-gradient(180deg, #FFFFFF 0%, #E8EAEE 100%);
  .agreement-headbar
    background: none
    position relative
    padding 0
    padding-left 80px
    ::v-deep .header-back
      img
        margin-left 0!important
  .agreement-content
    padding 0 180px 170px
    flex 1
    overflow-y scroll
    p
      margin-bottom 40px
    @media screen and (max-width: 1200px)
      padding 0 128px  170px
      font-size: 28px;
      p
        margin-bottom 30px
.agreement
  // &-title
  //   text-align center
  //   color #1D1D1F99
  //   font-size 48px
  //   line-height 1
  //   margin-top 0px
  //   margin-bottom 49px
  &-content
    line-height 40px
    font-size var(--font-size-medium)
    color #1D1D1F99
    padding-bottom 100px
.text-indent-2
  text-indent 62px
</style>
